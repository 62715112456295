import React from "react";
import Layout from "../components/Layout/Layout";

function music(props) {
  return (
    <Layout>
      <div>Music page coming soon..</div>
    </Layout>
  );
}

export default music;
